@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?a6k007");
  src: url("fonts/icomoon.eot?a6k007#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?a6k007") format("truetype"),
    url("fonts/icomoon.woff?a6k007") format("woff"),
    url("fonts/icomoon.svg?a6k007#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-eye-open:before {
  content: "\e900";
}
.icon-eye-closed:before {
  content: "\e904";
}
.icon-plus:before {
  content: "\e901";
}
.icon-minus:before {
  content: "\e902";
}
.icon-check:before {
  content: "\e903";
}
