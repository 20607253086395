table , td, th {
    border: 1px solid #023342;
    border-collapse: collapse;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    }
  td, th {
  padding: 3px;
  width: 100px;
  height: 25px;
  }
  