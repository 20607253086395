@import url(https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@400;500&family=Noto+Sans+JP:wght@500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Typography Settings */
html {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 10px;
  color: #023342;
}

h1 {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 5.6rem;
}

h2 {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 3.2rem;
}

h3 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.4rem;
}

h4 {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.4rem;
}

p {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
}

.c-heading--center {
  text-align: center;
}

.c-heading--left {
  text-align: left;
}

.c-heading--orange-blaze {
  color: #F26419;
}

.c-heading--1 {
  font-size: 3.2rem;
  line-height: 4.8rem;
  letter-spacing: .032rem;
  font-weight: 700;
}

.c-heading--2 {
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: 500;
}

.c-heading--3 {
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 500;
}

.c-subtitle {
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 500;
}

.c-subtitle--center {
  text-align: center;
}

.c-subtitle--left {
  text-align: left;
}

.c-subtitle--bold {
  font-weight: 700;
}

.c-paragraph {
  margin-top: 8px;
  font-family: 'M PLUS 1p', sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem !important;
  letter-spacing: .031rem;
  font-weight: 400;
}

.c-paragraph--center {
  text-align: center;
}

.c-paragraph--left {
  text-align: left;
}

.c-paragraph--dark-slate-gray {
  color: #3D565A;
}

.c-info {
  font-family: 'M PLUS 1p', sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
}

.c-link {
  font-family: 'M PLUS 1p', sans-serif;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  color: #009CD1;
}

.c-button-text {
  margin: 0 40px;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem !important;
  font-weight: 700;
  text-align: center;
}

.c-button-text--left {
  text-align: left;
}

.c-warning {
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 500;
  color: #F26419;
}

.c-warning--red {
  color: #E71D36;
}

.c-warning--icon::before {
  content: '!';
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  color: white;
  background-color: #F26419;
  border-radius: 50%;
}

.c-warning--icon-red::before {
  content: '!';
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  color: white;
  background-color: #E71D36;
  border-radius: 50%;
}

.c-mt0 {
  margin-top: 0px !important;
}

.c-mb0 {
  margin-bottom: 0px !important;
}

.c-mr0 {
  margin-right: 0px !important;
}

.c-ml0 {
  margin-left: 0px !important;
}

.c-mt8 {
  margin-top: 8px !important;
}

.c-mb8 {
  margin-bottom: 8px !important;
}

.c-mr8 {
  margin-right: 8px !important;
}

.c-ml8 {
  margin-left: 8px !important;
}

.c-mt16 {
  margin-top: 16px !important;
}

.c-mb16 {
  margin-bottom: 16px !important;
}

.c-mr16 {
  margin-right: 16px !important;
}

.c-ml16 {
  margin-left: 16px !important;
}

.c-mt24 {
  margin-top: 24px !important;
}

.c-mb24 {
  margin-bottom: 24px !important;
}

.c-mr24 {
  margin-right: 24px !important;
}

.c-ml24 {
  margin-left: 24px !important;
}

.c-mt32 {
  margin-top: 32px !important;
}

.c-mb32 {
  margin-bottom: 32px !important;
}

.c-mr32 {
  margin-right: 32px !important;
}

.c-ml32 {
  margin-left: 32px !important;
}

.c-mt40 {
  margin-top: 40px !important;
}

.c-mb40 {
  margin-bottom: 40px !important;
}

.c-mr40 {
  margin-right: 40px !important;
}

.c-ml40 {
  margin-left: 40px !important;
}

.c-mt48 {
  margin-top: 48px !important;
}

.c-mb48 {
  margin-bottom: 48px !important;
}

.c-mr48 {
  margin-right: 48px !important;
}

.c-ml48 {
  margin-left: 48px !important;
}

.c-mt56 {
  margin-top: 56px !important;
}

.c-mb56 {
  margin-bottom: 56px !important;
}

.c-mr56 {
  margin-right: 56px !important;
}

.c-ml56 {
  margin-left: 56px !important;
}

.c-mt64 {
  margin-top: 64px !important;
}

.c-mb64 {
  margin-bottom: 64px !important;
}

.c-mr64 {
  margin-right: 64px !important;
}

.c-ml64 {
  margin-left: 64px !important;
}

.c-mt72 {
  margin-top: 72px !important;
}

.c-mb72 {
  margin-bottom: 72px !important;
}

.c-mr72 {
  margin-right: 72px !important;
}

.c-ml72 {
  margin-left: 72px !important;
}

.c-mt80 {
  margin-top: 80px !important;
}

.c-mb80 {
  margin-bottom: 80px !important;
}

.c-mr80 {
  margin-right: 80px !important;
}

.c-ml80 {
  margin-left: 80px !important;
}

.c-mt88 {
  margin-top: 88px !important;
}

.c-mb88 {
  margin-bottom: 88px !important;
}

.c-mr88 {
  margin-right: 88px !important;
}

.c-ml88 {
  margin-left: 88px !important;
}

.c-mt96 {
  margin-top: 96px !important;
}

.c-mb96 {
  margin-bottom: 96px !important;
}

.c-mr96 {
  margin-right: 96px !important;
}

.c-ml96 {
  margin-left: 96px !important;
}

.c-mt104 {
  margin-top: 104px !important;
}

.c-mb104 {
  margin-bottom: 104px !important;
}

.c-mr104 {
  margin-right: 104px !important;
}

.c-ml104 {
  margin-left: 104px !important;
}

.c-mt112 {
  margin-top: 112px !important;
}

.c-mb112 {
  margin-bottom: 112px !important;
}

.c-mr112 {
  margin-right: 112px !important;
}

.c-ml112 {
  margin-left: 112px !important;
}

.c-mt120 {
  margin-top: 120px !important;
}

.c-mb120 {
  margin-bottom: 120px !important;
}

.c-mr120 {
  margin-right: 120px !important;
}

.c-ml120 {
  margin-left: 120px !important;
}

.c-mt128 {
  margin-top: 128px !important;
}

.c-mb128 {
  margin-bottom: 128px !important;
}

.c-mr128 {
  margin-right: 128px !important;
}

.c-ml128 {
  margin-left: 128px !important;
}

.c-mt136 {
  margin-top: 136px !important;
}

.c-mb136 {
  margin-bottom: 136px !important;
}

.c-mr136 {
  margin-right: 136px !important;
}

.c-ml136 {
  margin-left: 136px !important;
}

.c-mt144 {
  margin-top: 144px !important;
}

.c-mb144 {
  margin-bottom: 144px !important;
}

.c-mr144 {
  margin-right: 144px !important;
}

.c-ml144 {
  margin-left: 144px !important;
}

.c-mt152 {
  margin-top: 152px !important;
}

.c-mb152 {
  margin-bottom: 152px !important;
}

.c-mr152 {
  margin-right: 152px !important;
}

.c-ml152 {
  margin-left: 152px !important;
}

.c-mt160 {
  margin-top: 160px !important;
}

.c-mb160 {
  margin-bottom: 160px !important;
}

.c-mr160 {
  margin-right: 160px !important;
}

.c-ml160 {
  margin-left: 160px !important;
}

.c-mt168 {
  margin-top: 168px !important;
}

.c-mb168 {
  margin-bottom: 168px !important;
}

.c-mr168 {
  margin-right: 168px !important;
}

.c-ml168 {
  margin-left: 168px !important;
}

.c-mt176 {
  margin-top: 176px !important;
}

.c-mb176 {
  margin-bottom: 176px !important;
}

.c-mr176 {
  margin-right: 176px !important;
}

.c-ml176 {
  margin-left: 176px !important;
}

.c-mt184 {
  margin-top: 184px !important;
}

.c-mb184 {
  margin-bottom: 184px !important;
}

.c-mr184 {
  margin-right: 184px !important;
}

.c-ml184 {
  margin-left: 184px !important;
}

.c-mt192 {
  margin-top: 192px !important;
}

.c-mb192 {
  margin-bottom: 192px !important;
}

.c-mr192 {
  margin-right: 192px !important;
}

.c-ml192 {
  margin-left: 192px !important;
}

.c-mt200 {
  margin-top: 200px !important;
}

.c-mb200 {
  margin-bottom: 200px !important;
}

.c-mr200 {
  margin-right: 200px !important;
}

.c-ml200 {
  margin-left: 200px !important;
}

.c-mt208 {
  margin-top: 208px !important;
}

.c-mb208 {
  margin-bottom: 208px !important;
}

.c-mr208 {
  margin-right: 208px !important;
}

.c-ml208 {
  margin-left: 208px !important;
}

.c-mt216 {
  margin-top: 216px !important;
}

.c-mb216 {
  margin-bottom: 216px !important;
}

.c-mr216 {
  margin-right: 216px !important;
}

.c-ml216 {
  margin-left: 216px !important;
}

.c-mt224 {
  margin-top: 224px !important;
}

.c-mb224 {
  margin-bottom: 224px !important;
}

.c-mr224 {
  margin-right: 224px !important;
}

.c-ml224 {
  margin-left: 224px !important;
}

.c-mt232 {
  margin-top: 232px !important;
}

.c-mb232 {
  margin-bottom: 232px !important;
}

.c-mr232 {
  margin-right: 232px !important;
}

.c-ml232 {
  margin-left: 232px !important;
}

.c-mt240 {
  margin-top: 240px !important;
}

.c-mb240 {
  margin-bottom: 240px !important;
}

.c-mr240 {
  margin-right: 240px !important;
}

.c-ml240 {
  margin-left: 240px !important;
}

.c-mt248 {
  margin-top: 248px !important;
}

.c-mb248 {
  margin-bottom: 248px !important;
}

.c-mr248 {
  margin-right: 248px !important;
}

.c-ml248 {
  margin-left: 248px !important;
}

.c-mt256 {
  margin-top: 256px !important;
}

.c-mb256 {
  margin-bottom: 256px !important;
}

.c-mr256 {
  margin-right: 256px !important;
}

.c-ml256 {
  margin-left: 256px !important;
}

.c-mt264 {
  margin-top: 264px !important;
}

.c-mb264 {
  margin-bottom: 264px !important;
}

.c-mr264 {
  margin-right: 264px !important;
}

.c-ml264 {
  margin-left: 264px !important;
}

.c-mt272 {
  margin-top: 272px !important;
}

.c-mb272 {
  margin-bottom: 272px !important;
}

.c-mr272 {
  margin-right: 272px !important;
}

.c-ml272 {
  margin-left: 272px !important;
}

.c-mt280 {
  margin-top: 280px !important;
}

.c-mb280 {
  margin-bottom: 280px !important;
}

.c-mr280 {
  margin-right: 280px !important;
}

.c-ml280 {
  margin-left: 280px !important;
}

.c-mt288 {
  margin-top: 288px !important;
}

.c-mb288 {
  margin-bottom: 288px !important;
}

.c-mr288 {
  margin-right: 288px !important;
}

.c-ml288 {
  margin-left: 288px !important;
}

.c-mt296 {
  margin-top: 296px !important;
}

.c-mb296 {
  margin-bottom: 296px !important;
}

.c-mr296 {
  margin-right: 296px !important;
}

.c-ml296 {
  margin-left: 296px !important;
}

.c-mt304 {
  margin-top: 304px !important;
}

.c-mb304 {
  margin-bottom: 304px !important;
}

.c-mr304 {
  margin-right: 304px !important;
}

.c-ml304 {
  margin-left: 304px !important;
}

.c-mt312 {
  margin-top: 312px !important;
}

.c-mb312 {
  margin-bottom: 312px !important;
}

.c-mr312 {
  margin-right: 312px !important;
}

.c-ml312 {
  margin-left: 312px !important;
}

.c-mt320 {
  margin-top: 320px !important;
}

.c-mb320 {
  margin-bottom: 320px !important;
}

.c-mr320 {
  margin-right: 320px !important;
}

.c-ml320 {
  margin-left: 320px !important;
}

.c-mt328 {
  margin-top: 328px !important;
}

.c-mb328 {
  margin-bottom: 328px !important;
}

.c-mr328 {
  margin-right: 328px !important;
}

.c-ml328 {
  margin-left: 328px !important;
}

.c-mt336 {
  margin-top: 336px !important;
}

.c-mb336 {
  margin-bottom: 336px !important;
}

.c-mr336 {
  margin-right: 336px !important;
}

.c-ml336 {
  margin-left: 336px !important;
}

.c-mt344 {
  margin-top: 344px !important;
}

.c-mb344 {
  margin-bottom: 344px !important;
}

.c-mr344 {
  margin-right: 344px !important;
}

.c-ml344 {
  margin-left: 344px !important;
}

.c-mt352 {
  margin-top: 352px !important;
}

.c-mb352 {
  margin-bottom: 352px !important;
}

.c-mr352 {
  margin-right: 352px !important;
}

.c-ml352 {
  margin-left: 352px !important;
}

.c-mt360 {
  margin-top: 360px !important;
}

.c-mb360 {
  margin-bottom: 360px !important;
}

.c-mr360 {
  margin-right: 360px !important;
}

.c-ml360 {
  margin-left: 360px !important;
}

.c-mt368 {
  margin-top: 368px !important;
}

.c-mb368 {
  margin-bottom: 368px !important;
}

.c-mr368 {
  margin-right: 368px !important;
}

.c-ml368 {
  margin-left: 368px !important;
}

.c-mt376 {
  margin-top: 376px !important;
}

.c-mb376 {
  margin-bottom: 376px !important;
}

.c-mr376 {
  margin-right: 376px !important;
}

.c-ml376 {
  margin-left: 376px !important;
}

.c-margin--8 {
  margin: 8px;
}

.c-margin-reset {
  margin: 0 !important;
}

.c-padding--8 {
  padding: 8px !important;
}

.c-padding--16 {
  padding: 16px;
}

.c-padding--24 {
  padding: 24px;
}

.c-padding--32 {
  padding: 32px;
}

.c-padding--56 {
  padding: 56px;
}

.c-prl--8 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.c-prl--16 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.c-prl--24 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.c-prl--32 {
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.c-prl--56 {
  padding-right: 56px !important;
  padding-left: 56px !important;
}

.c-prl--88 {
  padding-right: 88px !important;
  padding-left: 88px !important;
}

.c-pr--8 {
  padding-right: 8px !important;
}

.c-pr--16 {
  padding-right: 16px !important;
}

.c-pr--24 {
  padding-right: 24px !important;
}

.c-pr--32 {
  padding-right: 32px !important;
}

.c-pr--56 {
  padding-right: 56px !important;
}

.c-pr--88 {
  padding-right: 88px !important;
}

.c-pr--144 {
  padding-right: 144px !important;
}

.c-pl--8 {
  padding-left: 8px !important;
}

.c-pl--16 {
  padding-left: 16px !important;
}

.c-pl--24 {
  padding-left: 24px !important;
}

.c-pl--32 {
  padding-left: 32px !important;
}

.c-pl--56 {
  padding-left: 56px !important;
}

.c-pl--88 {
  padding-left: 88px !important;
}

.c-padding-horizontal--8 {
  padding-right: 8px;
  padding-left: 8px;
}

.c-padding-horizontal--16 {
  padding-right: 16px;
  padding-left: 16px;
}

.c-padding-horizontal--24 {
  padding-right: 24px;
  padding-left: 24px;
}

.c-padding-horizontal--32 {
  padding-right: 32px;
  padding-left: 32px;
}

.c-padding-horizontal--56 {
  padding-right: 56px;
  padding-left: 56px;
}

.c-padding-horizontal--88 {
  padding-right: 88px;
  padding-left: 88px;
}

.c-radius--8 {
  border-radius: 8px !important;
}

.c-radius--16 {
  border-radius: 16px !important;
}

.c-radius--24 {
  border-radius: 24px !important;
}

.c-radius--32 {
  border-radius: 32px !important;
}

.c-radius-down--8 {
  border-radius: 0 0 8px 8px;
}

.c-radius-down--16 {
  border-radius: 0 0 16px 16px;
}

.c-radius-down--24 {
  border-radius: 0 0 24px 24px;
}

.c-radius-down--32 {
  border-radius: 0 0 32px 32px;
}

.c-radius-up--8 {
  border-radius: 8px 8px 0 0;
}

.c-radius-up--16 {
  border-radius: 16px 16px 0 0;
}

.c-radius-up--24 {
  border-radius: 24px 24px 0 0;
}

.c-radius-up--32 {
  border-radius: 32px 32px 0 0;
}

.c-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: auto;
  height: auto;
  min-height: 100vh;
  color: #023342;
  background-color: white;
}

.c-screen--gunmetal {
  color: white;
  background-color: #023342;
}

.c-page-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100vw;
  height: auto;
  color: #023342;
  background-color: white;
}

.c-page-section--gunmetal {
  color: white;
  background-color: #023342;
}

.c-page-section--dark-slate-gray {
  color: white;
  background-color: #3D565A;
}

.c-page-section--laurel-green {
  color: #023342;
  background-color: #9cae94;
}

.c-page-section--pale-spring-bud {
  color: #023342;
  background-color: #e4e0be;
}

.c-page-section--ivory {
  color: #023342;
  background-color: #faf9ed;
}

.c-top-triangle--gunmetal::before {
  content: "";
  position: absolute;
  top: -1px;
  left: calc(50% - 120px);
  right: 0;
  width: 0;
  height: 0;
  border-left: 120px solid transparent;
  border-right: 120px solid transparent;
  border-top: 40px solid #023342;
}

.c-top-triangle--dark-slate-gray::before {
  content: "";
  position: absolute;
  top: -1px;
  left: calc(50% - 120px);
  right: 0;
  width: 0;
  height: 0;
  border-left: 120px solid transparent;
  border-right: 120px solid transparent;
  border-top: 40px solid #3D565A;
}

.c-top-triangle--laurel-green::before {
  content: "";
  position: absolute;
  top: -1px;
  left: calc(50% - 120px);
  right: 0;
  width: 0;
  height: 0;
  border-left: 120px solid transparent;
  border-right: 120px solid transparent;
  border-top: 40px solid #9cae94;
}

.c-top-triangle--pale-spring-bud::before {
  content: "";
  position: absolute;
  top: -1px;
  left: calc(50% - 120px);
  right: 0;
  width: 0;
  height: 0;
  border-left: 120px solid transparent;
  border-right: 120px solid transparent;
  border-top: 40px solid #e4e0be;
}

.c-top-triangle--ivory::before {
  content: "";
  position: absolute;
  top: -1px;
  left: calc(50% - 120px);
  right: 0;
  width: 0;
  height: 0;
  border-left: 120px solid transparent;
  border-right: 120px solid transparent;
  border-top: 40px solid #faf9ed;
}

.c-top-triangle--white::before {
  content: "";
  position: absolute;
  top: -1px;
  left: calc(50% - 120px);
  right: 0;
  width: 0;
  height: 0;
  border-left: 120px solid transparent;
  border-right: 120px solid transparent;
  border-top: 40px solid white;
}

.c-balanced-width {
  width: 100%;
  max-width: 608px;
  padding: 0 32px;
}

.c-balanced-width--376 {
  max-width: 376px;
}

.c-container-vertical {
  display: flex;
  flex-direction: column;
  position: relative;
}

@media (min-width: 900px) {
  .c-container-vertical--responsive {
    flex-direction: row;
  }
}

.c-container-vertical--center {
  align-items: center;
}

.c-container-vertical--left {
  align-items: flex-start;
}

.c-container-vertical--white {
  color: #023342;
  background-color: white;
}

.c-container-vertical--gunmetal {
  color: white;
  background-color: #023342;
}

.c-container-vertical--pale-spring-bud {
  color: #023342;
  background-color: #e4e0be;
}

.c-container-vertical--dark-slate-gray {
  color: white;
  background-color: #3D565A;
}

.c-container-vertical--lauren-green {
  color: #023342;
  background-color: #9cae94;
}

.c-container-vertical--ivory {
  color: #023342;
  background-color: #faf9ed;
}

.c-container-vertical--orange-blaze {
  color: white;
  background-color: #F26419;
}

.c-container-vertical--232 {
  width: 232px;
}

.c-container-vertical--608 {
  width: 608px;
}

.c-container-vertical--842 {
  width: 842px;
}

.c-container-vertical--934 {
  width: 934px;
}

.c-container-horizontal {
  display: flex;
  position: relative;
}

.c-container-horizontal--center {
  justify-content: center;
  align-items: center;
}

.c-container-horizontal--space-between {
  justify-content: space-between;
}

.c-container-horizontal--space-around {
  justify-content: space-around;
}

.c-container-horizontal--wrap {
  flex-wrap: wrap;
}

.c-container-horizontal--responsive {
  flex-direction: column;
}

@media (min-width: 600px) {
  .c-container-horizontal--responsive {
    flex-direction: row;
  }
}

.c-container-horizontal--left {
  width: 100%;
  justify-content: flex-start;
}

.c-container-horizontal--left-center {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.c-container-horizontal--white {
  color: #023342;
  background-color: white;
}

.c-container-horizontal--gunmetal {
  color: white;
  background-color: #023342;
}

.c-container-horizontal--pale-spring-bud {
  color: #023342;
  background-color: #e4e0be;
}

.c-container-horizontal--lauren-green {
  color: #023342;
  background-color: #9cae94;
}

.c-container-horizontal--dark-slate-gray {
  color: white;
  background-color: #3D565A;
}

.kanri-container {
  margin: auto;
  text-align: center;
}

@media (min-width: 600px) {
  .kanri-container {
    width: 608px;
  }
}

.kanri-container--medium {
  width: 376px;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-6-14 15:9:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
@keyframes jelly {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-6-14 15:9:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.06, 1.02, 1.6);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-6-14 15:9:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
@keyframes rotate-in-center {
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(0);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-6-14 15:9:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
@keyframes scale-in-center {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-6-16 21:37:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation wobble-hor-top
 * ----------------------------------------
 */

@keyframes wobble-hor-top {
  0%,
  100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }
  15% {
    transform: translateX(-30px) rotate(6deg);
  }
  30% {
    transform: translateX(15px) rotate(-6deg);
  }
  45% {
    transform: translateX(-15px) rotate(3.6deg);
  }
  60% {
    transform: translateX(9px) rotate(-2.4deg);
  }
  75% {
    transform: translateX(-6px) rotate(1.2deg);
  }
}

.animation--jelly {
  animation: jelly 0.9s both;
}

.animation--pulse {
  animation: pulse 0.55s both;
}

.animation--rotate {
  animation: rotate-in-center 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.animation--zoomIn {
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.animation--wooble-hor-top {
  animation: wobble-hor-top 0.8s both;
}

.c-accordion {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  background-color: #e4e0be;
}

.c-accordion--active {
  background-color: #023342;
  transition: background-color .11s;
  animation: pulse 0.55s both;
}

.c-accordion--active button {
  color: white;
  transition: color .33s;
}

.c-accordion--active svg {
  fill: white !important;
}

.c-accordion--active .c-accordion__icon {
  transform-origin: center;
  transform: rotateZ(180deg);
}

.c-accordion__button {
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.c-accordion__button--88 {
  height: 88px;
  min-height: 88px;
  padding: 16px 32px;
}

.c-accordion__title {
  display: inherit;
  align-items: center;
}

.c-accordion__icon-container {
  justify-content: center;
  height: 56px;
}

.c-accordion__icon {
  transition: transform .11s ease-in;
}

.c-accordion__icon svg {
  width: 100%;
  height: 100%;
  fill: #023342;
}

.c-accordion__icon--white svg {
  fill: white;
}

.c-accordion__icon--acid-green svg {
  fill: #cacb45;
}

.c-accordion__icon--8 {
  width: 8px;
  height: 8px;
}

.c-accordion__icon--16 {
  width: 16px;
  height: 16px;
}

.c-accordion__icon--24 {
  width: 24px;
  height: 24px;
}

.c-accordion__icon--32 {
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
}

.c-accordion__content {
  display: none;
  height: 0;
  flex: 0 0 auto;
  margin: 0;
  border-right: 8px solid #023342;
  transition: max-height 1.77s ease-in;
}

.c-accordion__content--extended {
  display: flex;
  height: auto;
  transition: max-height 1.77s;
  flex: 0 1 auto;
  transition: flex .66s ease-in;
  transition: display .33s ease-in;
}

.c-accordion__scrollable-content {
  display: flex;
  height: 580px;
  overflow-y: scroll;
  transition: max-height 1.77s;
  flex: 0 1 auto;
  transition: flex .66s ease-in;
  transition: display .33s ease-in;
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

.c-accordion__scrollable-content::-webkit-scrollbar-track {
  border-radius: 16px;
  background-color: #faf9ed;
}

.c-accordion__scrollable-content::-webkit-scrollbar {
  width: 24px;
  background-color: #023342;
}

.c-accordion__scrollable-content::-webkit-scrollbar-thumb {
  background-color: #3D565A;
  border: 1px solid #faf9ed;
  border-radius: 16px;
}

.c-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8px;
  min-width: 8px;
  height: 8px;
  min-height: 8px;
  margin: 0 8px;
  color: white;
  background-color: #023342;
  border-radius: 50%;
}

.c-badge--square {
  border-radius: 2px;
}

.c-badge--16 {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
}

.c-badge--24 {
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
}

.c-badge--32 {
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
}

.c-badge--56 {
  width: 56px;
  min-width: 56px;
  height: 56px;
  min-height: 56px;
}

.c-badge--acid-green {
  color: #023342;
  background-color: #cacb45;
}

.c-badge--orange-blaze {
  color: white;
  background-color: #F26419;
}

.c-badge--misty-rose {
  color: #023342;
  background-color: #FFE3E3;
}

.c-badge--ivory {
  color: #023342;
  background-color: #faf9ed;
}

.c-badge--cream {
  color: #023342;
  background-color: #FCF6C5;
}

.kanri-breadcrumb {
  display: flex;
  justify-content: center;
  position: relative;
  color: white;
}

.kanri-breadcrumb svg {
  fill: white !important;
}

.kanri-breadcrumb__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 144px;
  min-width: 144px;
  margin-right: 32px;
  opacity: .33;
}

.kanri-breadcrumb__item::after {
  content: '';
  position: absolute;
  top: 32px;
  left: 80px;
  width: 160px;
  height: 1px;
  background-color: white;
}

.kanri-breadcrumb__item:last-of-type {
  margin-right: 0;
}

.kanri-breadcrumb__item:last-of-type::after {
  display: none;
}

.kanri-breadcrumb__item--active {
  color: #cacb45;
  opacity: 1;
}

.kanri-breadcrumb__item--active svg {
  fill: #cacb45 !important;
}

.kanri-breadcrumb__item--active::after {
  opacity: .33;
}

.kanri-breadcrumb__label {
  height: 24px;
  margin-top: 8px;
}

.kanri-breadcrumb__line {
  position: absolute;
  top: 32px;
  left: 80px;
  width: 160px;
  height: 1px;
  background-color: white;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: auto;
  min-width: 232px;
  max-width: 608px;
  height: 56px;
  margin: 32px 0 0 0;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 16px;
  border-radius: 32px;
  outline: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button:disabled {
  opacity: .33;
}

.button:disabled:hover {
  animation: none;
}

.button:hover {
  animation: pulse 0.55s both;
}

.button--small {
  height: 32px;
  width: 116px;
  min-width: 88px;
}

.button__primary {
  color: #fff;
  background-color: #cacb45;
  border-color: #cacb45;
}

.button__primary--red {
  color: white;
  background-color: #E71D36;
  border-color: #E71D36;
}

.button__secondary {
  color: #023342 !important;
  background-color: white;
  border-color: #023342;
}

.button__tertiary {
  color: #023342;
  background: transparent;
  text-decoration: underline;
}

.button__tertiary--red {
  color: #E71D36;
}

.button__tertiary--left {
  padding: 0;
  justify-content: flex-start;
}

.button__tertiary--viewmore::after {
  content: '';
  position: absolute;
  top: 42px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 14px solid #023342;
}

.button__data {
  height: 32px;
  width: 88px;
  min-width: 56px;
  margin: 0;
  padding: 0 4px;
  border: 2px solid rgba(255, 255, 255, 0.44);
  border-radius: 4px;
  color: white;
  font-weight: 400;
  background-color: #3D565A;
}

.button__data--red {
  color: white;
  background-color: #E71D36;
}

.button__data--green {
  color: #023342 !important;
  background-color: #cacb45;
}

.button__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 2px;
  width: 48px;
  height: 48px;
  background-color: transparent;
  border-radius: 44px;
}

.button__icon--gunmetal {
  background-color: #023342;
}

.button__icon--32 {
  width: 32px;
  height: 32px;
}

.button__delete {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  min-width: 16px;
  height: 48px;
  min-height: 16px;
  margin: 0;
  padding: 0;
  color: white;
  background-color: #E71D36;
  border-radius: 50%;
}

.c-button-set {
  display: flex;
  flex-direction: column;
  width: 376px;
  margin: 24px auto 0;
}

@media (min-width: 600px) {
  .c-button-set {
    max-width: 376px;
  }
}

.c-button-set--horizontal {
  flex-direction: row;
}

.c-button-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  border-radius: 50%;
  color: white;
  background-color: #E71D36;
  border: none;
}

.c-button-delete svg {
  fill: white;
  transform: rotate(45deg);
}

.c-button-delete:hover {
  animation: pulse 0.55s both;
}

.c-radio-buttons {
  display: inline-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.c-radio-buttons--confirm input:checked + label {
  color: #023342 !important;
  background: #e4e0be !important;
  border: none;
}

.c-radio-buttons--confirm .c-radio-buttons__label {
  opacity: .33;
}

.c-radio-buttons--gunmetal input:checked + label {
  color: white;
  background: #023342;
}

.c-radio-buttons--gunmetal .c-radio-buttons__label {
  opacity: .55;
}

.c-radio-buttons--center {
  justify-content: center;
}

.c-radio-buttons input[type="radio"] {
  visibility: hidden;
  position: absolute;
  top: -5000px;
  left: -5000px;
}

.c-radio-buttons input[type="radio"]:disabled ~ .c-radio-buttons__label:hover {
  cursor: default;
  animation: none;
}

.c-radio-buttons input:checked + label {
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1;
}

.c-radio-buttons__label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  min-width: 88px;
  height: 32px;
  margin: 0 8px;
  color: #023342;
  background-color: white;
  border: 1px solid #023342;
  border-radius: 16px;
  cursor: pointer;
  opacity: .33;
}

.c-radio-buttons__label:hover {
  animation: pulse 0.55s both;
}

.c-radio-buttons__label--232 {
  width: 232px;
  min-width: 232px;
}

.c-radio-button-set {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.c-radio-button-set input[type="radio"] {
  display: none;
}

.c-radio-button-set__label {
  display: flex;
}

.c-radio-button-set__label--center {
  justify-content: center;
}

.kanri-option {
  display: flex;
  align-items: center;
  width: 376px;
  height: 88px;
  margin: 0 56px 0 0;
  padding: 0;
  color: #023342;
  background: white;
  border: none;
  border-radius: 44px;
  outline: none;
}

.kanri-option:last-of-type {
  margin: 0;
}

.kanri-option:hover {
  background: #cacb45;
  animation: pulse 0.55s both;
}

.kanri-option:hover .kanri-option__icon {
  border: 4px solid #cacb45;
}

.kanri-option:hover .kanri-option__icon svg {
  fill: #cacb45;
  animation: jelly 0.9s both;
}

.kanri-option__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  min-width: 88px;
  height: 88px;
  min-height: 88px;
  background: #023342;
  border: 4px solid white;
  border-radius: 44px;
}

.kanri-option__text {
  padding-left: 8px;
  line-height: 4rem;
  text-align: left;
}

.c-data {
  display: flex;
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.c-data__column {
  display: flex;
  flex-direction: column;
  width: 88px;
  margin-left: 2px;
}

.c-data__title {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1;
  width: 88px;
  max-width: 88px;
  height: 56px;
  min-height: 32px;
  margin: 0 2px 2px 0;
  padding: 8px 16px;
  color: white;
  background-color: #023342;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 500;
  border-radius: 4px;
}

.c-data__title--h32 {
  height: 32px;
}

.c-data__title--32 {
  width: 32px;
  max-width: 32px;
}

.c-data__title--56 {
  width: 56px;
  max-width: 56px;
}

.c-data__title--88 {
  width: 88px;
  max-width: 88px;
}

.c-data__title--144 {
  width: 144px;
  max-width: 144px;
}

.c-data__title--232 {
  width: 232px;
  max-width: 232px;
}

.c-data__title--center {
  justify-content: center;
}

.c-data__title--laurel-green {
  background-color: #9cae94;
}

.c-data__title--dark-slate-gray {
  background-color: #3D565A;
}

.c-data__slot {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 2 1;
  width: 88px;
  max-width: 88px;
  height: 56px;
  min-height: 32px;
  margin: 0 2px 2px 0;
  padding: 8px 16px;
  color: #023342;
  background-color: #faf9ed;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  overflow: hidden;
}

.c-data__slot--variable-height {
  height: auto;
}

.c-data__slot--pale-spring-bud {
  background-color: #e4e0be;
}

.c-data__slot--score-not-defined {
  background-color: #eaeeef !important;
}

.c-data__slot--white {
  background-color: white;
  border: 1px solid #e4e0be;
}

.c-data__slot--short-height {
  height: 32px;
}

.c-data__slot--32 {
  width: 32px;
  max-width: 32px;
}

.c-data__slot--56 {
  width: 56px;
  max-width: 56px;
}

.c-data__slot--88 {
  width: 88px;
  max-width: 88px;
}

.c-data__slot--144 {
  width: 144px;
  max-width: 144px;
}

.c-data__slot--178 {
  width: 178px;
  max-width: 178px;
}

.c-data__slot--232 {
  width: 232px;
  max-width: 232px;
}

.c-data__slot--center {
  justify-content: center;
  text-align: center;
}

.c-data__slot--elimination {
  background-color: #FFE3E3 !important;
}

.c-data__slot--restore {
  background-color: #FCF6C5;
}

.c-data__slot--pending {
  background-color: #FFE3E3;
}

.c-data__slot--edit {
  padding-left: 16px;
  text-align: center;
  background-color: #edf0f1;
  border: 1px solid #9fb1b7;
}

.c-data__slot--confirm {
  color: white !important;
  background-color: #F26419;
}

.c-data__slot--added {
  color: white;
  background-color: #F26419;
}

.c-data__stamp {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  margin-right: 8px;
  color: white;
  background-color: #023342;
  border-radius: 50%;
}

.double-slot {
  display: flex;
  width: 100%;
  text-align: center;
}

.double-slot--vertical {
  flex-direction: column;
}

.double-slot__slot {
  flex: 1 1;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.double-slot__slot:first-of-type {
  border-right: 1px solid;
}

.game-data {
  display: flex;
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.game-data__column {
  display: flex;
  flex-direction: column;
  width: 88px;
  margin-left: 2px;
}

.game-data__column--144 {
  width: 144px;
}

.game-data__title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 32px;
  margin: 0 2px 2px 0;
  color: white;
  background-color: #023342;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 500;
  border-radius: 4px;
}

.game-data__title--first {
  margin-left: 2px;
}

.game-data__title--144 {
  width: 144px;
}

.game-data__slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 2px 2px 0;
  color: #023342;
  background-color: #e4e0be;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 500;
  border-radius: 4px;
}

.game-data__slot--x2 {
  height: 66px;
}

.game-data__slot--x8 {
  height: 270px;
}

.game-data__slot--x72 {
  height: 2446px;
}

.game-data__slot--edit {
  padding-left: 16px;
  text-align: center;
  background-color: #edf0f1;
  border: 1px solid #9fb1b7;
}

.game-data__slot--confirm {
  color: white;
  background-color: #F26419;
}

.c-icon {
  margin: auto;
}

.c-icon svg {
  width: 100%;
  height: 100%;
  fill: #023342;
}

.c-icon--white svg {
  fill: white;
}

.c-icon--acid-green svg {
  fill: #cacb45;
}

.c-icon--orange-blaze svg {
  fill: #F26419;
}

.c-icon--8 {
  width: 8px;
  height: 8px;
}

.c-icon--16 {
  width: 16px;
  height: 16px;
}

.c-icon--24 {
  width: 24px;
  height: 24px;
}

.c-icon--32 {
  width: 32px;
  height: 32px;
}

.c-icon--56 {
  width: 56px;
  height: 56px;
}

.c-icon--88 {
  width: 88px;
  height: 88px;
}

.kanri-info {
  max-width: 608px;
  text-align: left;
}

.kanri-info__contents {
  padding: 8px 16px;
  color: #023342;
  background-color: #EDF0F1;
  border: 1px solid #96ADBB;
  border-radius: 8px;
}

.kanri-info__contents p {
  margin-bottom: 4px;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="number"],
input[type="date"],
input[type="time"],
option,
select {
  color: #023342;
  font-size: 16px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-input-placeholder {
  font-family: "M PLUS 1p", sans-serif;
  color: rgba(61, 86, 90, 0.77);
}

:-ms-input-placeholder {
  font-family: "M PLUS 1p", sans-serif;
  color: rgba(61, 86, 90, 0.77);
}

::placeholder {
  font-family: "M PLUS 1p", sans-serif;
  color: rgba(61, 86, 90, 0.77);
}

.input {
  display: inline-flex;
  position: relative;
}

.input__legend {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 3.2rem;
}

.input__set {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 32px auto 0;
}

.input__set--kanri {
  width: 100%;
}

.input__set--no-mt {
  margin: 0;
}

.input__set--stretch {
  flex: 2 1 auto;
}

.input__set--checkbox {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.input__title {
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 32px;
  box-sizing: border-box;
}

.input__tag {
  height: 20px;
  margin: 0 8px;
  padding: 2px 8px;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #fff;
  background-color: #F26419;
  border-radius: 4px;
}

.input__label {
  align-items: center;
  justify-content: space-between;
  height: auto;
  min-height: 24px;
  max-height: 56px;
  padding-left: 8px;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 500;
  overflow: hidden;
}

.input__label--white {
  color: white;
}

.input__label--big {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.input__field {
  width: auto;
  min-width: 232px;
  max-width: 608px;
  height: 38px;
  margin-top: 4px;
  padding: 4px 16px;
  text-align: left;
  border: 1px solid #023342;
  border-radius: 8px;
  box-sizing: content-box;
}

.input__field--extend {
  width: 100%;
}

.input__field--minimal {
  min-width: auto;
}

.input__field--red-border {
  border: 1px solid #E71D36;
}

.input__field--rounded-right {
  border-radius: 8px 24px 24px 8px;
}

.input__field--textarea {
  max-width: 608px;
  min-height: 144px;
  padding: 8px 16px;
  color: #023342;
  resize: vertical;
}

.input__field--image {
  max-width: 608px;
  min-height: 144px;
  padding: 8px 16px;
  color: #023342;
  border: 2px dashed #F26419;
}

.input__field--small {
  width: 56px;
  min-width: 56px;
  margin-right: 8px;
}

.input__field--medium {
  width: 88px;
  min-width: 88px;
  margin-right: 8px;
}

.input__field--stretch {
  width: auto;
  min-width: 24px;
}

.input__field:disabled {
  opacity: 0.55;
  background: #e3e8ea;
  color: #042109;
  font-size: 1.6rem;
  transition: background 0.3s ease-in;
}

.input__suffix {
  margin: 0;
  padding-left: 8px;
  text-align: right;
}

.input__suffix--88 {
  width: 88px;
}

.input__checkbox {
  width: 24px;
  height: 24px;
}

.input__validation {
  display: none;
}

.input__validation--display {
  display: flex;
  align-items: center;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 24px;
  margin-top: 4px;
  padding: 0 8px;
  color: white;
  background-color: #F26419;
  border-radius: 8px;
  animation: jelly 0.9s both;
}

.input__validation--display::before {
  box-sizing: border-box;
  content: "!";
  width: 12px;
  height: 12px;
  margin-right: 4px;
  padding: 1px 4px;
  border-radius: 8px;
  color: #F26419;
  background-color: white;
  font-weight: 700;
}

.input__validation--display::after {
  content: "";
  position: absolute;
  top: -16px;
  left: 11px;
  width: 0;
  height: 0;
  border-top: 16px solid #F26419;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  transform: rotate(180deg);
}

.address {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.address__row {
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
}

.confirm {
  display: flex;
  position: relative;
  border: none;
}

.confirm__logo {
  width: 144px;
  height: auto;
  margin: 0 auto;
}

.confirm__logo img {
  width: 100%;
  height: 100%;
}

.confirm__title {
  text-align: left;
}

.confirm__vertical {
  flex-direction: column;
  margin-top: 32px;
}

.confirm__vertical--no-mt {
  margin-top: 0;
}

.confirm__vertical--stretch {
  flex: 2 1 auto;
}

.confirm__label {
  align-items: center;
  justify-content: space-between;
  height: auto;
  min-height: 24px;
  max-height: 56px;
  padding-left: 8px;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 500;
  overflow: hidden;
}

.confirm__field {
  align-items: center;
  min-width: 232px;
  height: 38px;
  margin-top: 4px;
  padding: 4px 16px;
  font-size: 1.6rem;
  line-height: 3.2rem;
  color: #042109;
  background: #e4e0be;
  border-radius: 8px;
  box-sizing: content-box;
  overflow: hidden;
}

.confirm__field:nth-child(n + 3) {
  margin-top: 8px;
}

.confirm__field--extend {
  width: 100%;
}

.confirm__field--small {
  min-width: 56px;
  margin-right: 8px;
}

.confirm__field--medium {
  min-width: 88px;
  margin-right: 8px;
}

.confirm__field--stretch {
  min-width: 24px;
}

.confirm__field--textarea {
  align-items: flex-start;
  max-width: 608px;
  height: auto;
  min-height: 144px;
  padding: 8px 16px;
  text-align: left;
}

.c-logo {
  margin: auto;
}

.c-logo--144 {
  width: 144px;
  height: 56px;
}

.c-logo--232 {
  width: 232px;
  height: auto;
}

.c-logo--376 {
  width: 376px;
  height: auto;
}

.c-logo__source {
  width: 100%;
  height: 100%;
}

.c-poster {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  max-width: calc(100vw - 112px);
  padding: 56px;
  color: #023342;
  background-color: white;
}

.c-poster--ivory {
  color: #023342;
  background-color: #faf9ed;
}

.c-poster--pale-spring-bud {
  color: #023342;
  background-color: #e4e0be;
}

.c-progress {
  display: flex;
  position: relative;
  height: 32px;
  width: 100%;
  background-color: #023342;
  border-radius: 16px;
}

.c-progress__bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 16px;
  margin: 8px;
  background-color: #cacb45;
  border-radius: 8px;
  transition: width 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.c-sort-buttons {
  display: inline-flex;
  width: 100%;
}

.c-sort-buttons input[type="radio"] {
  visibility: hidden;
  position: absolute;
  top: -5000px;
  left: -5000px;
}

.c-sort-buttons input:checked + label {
  color: white;
  background-color: #023342;
  transition: background-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.c-sort-buttons__label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 24px;
  margin: 0 8px;
  background-color: transparent;
  border: 1px solid #023342;
  border-radius: 16px;
  cursor: pointer;
}

.c-sort-buttons__label:hover {
  animation: pulse 0.55s both;
}

.c-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 24px;
  padding: 0 16px;
  color: white;
  text-align: center;
  line-break: anywhere;
  background-color: #3D565A;
  border-radius: 8px;
}

.c-tag--full {
  width: 100%;
}

.c-tag--margin {
  margin: 0 8px;
}

.c-tag--gunmetal {
  background-color: #023342;
}

.c-tag--orange-blaze {
  background-color: #F26419;
}

.c-tag--laurel-green {
  background-color: #9cae94;
}

.c-tag--white {
  color: #023342;
  background-color: white;
}

.kanri-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
}

.kanri-title__icon {
  margin-top: 7px;
  margin-right: 32px;
  animation: jelly 0.9s both;
}

.c-drag-and-drop {
  margin: 8px 0;
  padding: 8px 16px;
  background-color: rgba(2, 51, 66, 0.033);
  border: 1px dashed #023342;
  border-radius: 16px;
}

.c-drag-and-drop__preview img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.added-items {
  display: flex;
  flex-direction: column;
  width: 232px;
  margin: auto;
}

.added-items__item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 232px;
  height: 32px;
  margin-top: 24px;
  color: #023342;
  background-color: #e4e0be;
  border-radius: 16px;
}

.added-items__item:first-of-type {
  margin-top: 0;
}

.added-items__delete-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  background-color: #F26419;
  border: 4px solid #e4e0be;
  border-radius: 50%;
  outline: none;
}

.added-items__delete-button:hover {
  animation: pulse 0.55s both;
}

.added-items .button__icon {
  left: auto;
}

.course-add-items {
  width: 100%;
  padding: 16px;
  background-color: rgba(61, 86, 90, 0.44);
  border-radius: 16px;
}

.course-add-button {
  position: absolute;
  top: 10px;
  right: 7px;
}

.course-single-item {
  display: flex;
  align-items: center;
  height: 24px;
  margin: 8px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.11);
  border-radius: 16px;
}

.course-single-item__delete {
  position: relative;
  height: 16px;
  width: 16px;
  margin-left: 3px;
  margin-right: 8px;
  background-color: #E71D36;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none;
}

.course-single-item__delete:hover {
  animation: pulse 0.55s both;
}

.course-single-item__delete-icon {
  position: absolute;
  top: 2px;
  right: 1px;
  transform: rotate(45deg);
}

.add-course-validation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 16px;
  background-color: #F26419;
  border-radius: 16px;
  animation: jelly 0.9s both;
}

.add-course-validation__title {
  width: 100%;
  margin-bottom: 16px;
  padding: 8px;
  background-color: white;
  color: #F26419;
  border-radius: 8px;
}

.add-course-validation__item {
  display: inline-flex;
  align-items: center;
  margin-left: 16px;
  margin-bottom: 8px;
}

.add-course-validation__item::before {
  content: '!';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  font-size: 1.2rem;
  color: #F26419;
  background-color: white;
  border-radius: 50%;
}

.hole--18 {
  opacity: .33;
}

.hole--9 {
  opacity: .33;
}

.c-radio-button-set input:checked + label {
  opacity: 1;
}

.golf-course-add:first-of-type .golf-course-delete {
  display: none;
}

.golf-course-delete {
  position: absolute;
  top: -16px;
  right: -16px;
}

.search-member-info {
  position: relative;
  margin-top: 32px;
  padding: 24px 32px;
}

.search-member-info--deleted {
  margin-top: 56px;
  padding: 24px 32px;
  background-color: rgba(231, 29, 54, 0.05);
  border: 1px dashed #E71D36;
  border-radius: 16px;
}

.search-member-info--restore {
  margin-top: 56px;
  padding: 24px 32px;
  background-color: rgba(202, 203, 69, 0.05);
  border: 1px dashed #cacb45;
  border-radius: 16px;
}

.search-member-info__message-container {
  position: absolute;
  display: flex;
  justify-content: center;
  top: -16px;
  left: 0;
  right: 0;
}

.search-member-info__delete-message {
  display: inherit;
  align-items: center;
  padding: 8px 16px;
  width: auto;
  height: 32px;
  color: white;
  background-color: #E71D36;
  border-radius: 8px;
}

.search-member-info__delete-message i {
  display: inherit;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  font-weight: 700;
  color: #E71D36;
  background-color: white;
  border-radius: 50%;
}

.course-select__group {
  width: 232px;
  color: #023342;
  background-color: white;
  opacity: 0.77;
}

.course-select__group--selected {
  background-color: #faf9ed;
  opacity: 1;
  animation: pulse 0.55s both;
  transition: background-color 0.7s ease-in;
}

.course-select__confirm {
  width: 232px;
  color: #023342;
  background-color: #faf9ed;
  opacity: 1;
}

.course-select__confirm .course-select__label:hover {
  cursor: default;
}

.course-select__label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  text-align: left;
}

.course-select__label:hover {
  cursor: pointer;
}

.score-accordion {
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  width: 100%;
  overflow: hidden;
}

.score-accordion--active .score-accordion__button::after {
  content: "\e902";
  font-family: icomoon;
  font-size: 3.2rem;
  transform: rotateZ(180deg);
  transition: transform 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.score-accordion__button {
  width: 100%;
  background-color: #e4e0be;
  border: none;
  cursor: pointer;
}

.score-accordion__button::after {
  content: "\e901";
  font-family: icomoon;
  font-size: 3.2rem;
  transform: rotateZ(0deg);
  transition: transform 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.score-accordion__button:hover {
  animation: none !important;
}

.score-accordion__button--88 {
  height: 88px;
  min-height: 88px;
  padding: 16px 32px;
}

.score-accordion__title {
  display: inherit;
  align-items: center;
}

.score-accordion__icon-container {
  justify-content: center;
  height: 56px;
}

.score-accordion__icon-container:hover {
  animation: pulse 0.55s both;
}

.score-accordion__icon {
  transform: scale(1) rotateX(0deg);
  transition: transform 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.score-accordion__icon svg {
  width: 100%;
  height: 100%;
  fill: #023342;
}

.score-accordion__icon--white svg {
  fill: white;
}

.score-accordion__icon--acid-green svg {
  fill: #cacb45;
}

.score-accordion__icon--8 {
  width: 8px;
  height: 8px;
}

.score-accordion__icon--16 {
  width: 16px;
  height: 16px;
}

.score-accordion__icon--24 {
  width: 24px;
  height: 24px;
}

.score-accordion__icon--32 {
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
}

.score-accordion__content {
  display: none;
  flex-direction: column;
  align-items: center;
  margin: -10px 2px 8px 0;
  padding: 8px;
  border-radius: 0 0 8px 8px;
  background-color: #faf9ed;
  background: linear-gradient(0deg, #faf9ed 99.22%, rgba(250, 249, 237, 0) 100%);
  border-left: 1px solid #e4e0be;
  border-right: 1px solid #e4e0be;
  border-bottom: 1px solid #e4e0be;
  transition: all 1.77s ease-in;
}

.score-accordion__content--extended {
  display: flex;
}

.score-accordion__elimination-reason {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid rgba(242, 100, 25, 0.33);
  border-radius: 24px;
  background-color: white;
}

.score-accordion__elimination-reason .c-tag {
  padding: 8px;
  color: white;
  border-radius: 16px;
  background-color: #f26419;
}

.score-accordion__member-wrap {
  margin-top: 2px;
}

.score-accordion__scrollable-content {
  display: flex;
  height: 580px;
  overflow-y: scroll;
  transition: max-height 1.77s;
  flex: 0 1 auto;
  transition: flex 0.66s ease-in;
  transition: display 0.33s ease-in;
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

.score-accordion__scrollable-content::-webkit-scrollbar-track {
  border-radius: 16px;
  background-color: #faf9ed;
}

.score-accordion__scrollable-content::-webkit-scrollbar {
  width: 24px;
  background-color: #023342;
}

.score-accordion__scrollable-content::-webkit-scrollbar-thumb {
  background-color: #3D565A;
  border: 1px solid #faf9ed;
  border-radius: 16px;
}

.score-accordion__checkbox-input {
  position: absolute;
  left: -9999px;
}

.score-accordion__checkbox-label {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.score-accordion__checkbox-input:not(:checked) + .score-accordion__checkbox-label:before,
.score-accordion__checkbox-input + .score-accordion__checkbox-label:before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: white;
  border: 3px solid #023342;
  box-sizing: border-box;
  border-radius: 8px;
}

.score-accordion__checkbox-input:checked + .score-accordion__checkbox-label::before {
  background-color: #023342;
  transition: all 0.22s cubic-bezier(0.94, 0.06, 0.52, 0.95);
  transform: scale(1);
}

.score-accordion__checkbox-input:not(:checked) + .score-accordion__checkbox-label::before {
  background-color: white;
  transition: all 0.22s cubic-bezier(0.94, 0.06, 0.52, 0.95);
  transform: scale(0.77);
}

.score-accordion__checkbox-input:not(:checked) + .score-accordion__checkbox-label:after,
.score-accordion__checkbox-input:checked + .score-accordion__checkbox-label:after {
  display: flex;
  position: absolute;
  height: 100%;
  align-items: center;
  top: -2px;
  left: 4px;
  content: "\e903";
  font-family: icomoon;
  font-size: 2.4rem;
  font-weight: 700;
  color: #cacb45;
  text-shadow: 0px -2px 0px #023342;
  transform-origin: center center;
}

.score-accordion__checkbox-input:not(:checked) + .score-accordion__checkbox-label:after {
  transform: scale(1, 0);
}

.score-accordion__checkbox-input:checked + .score-accordion__checkbox-label:after {
  transform: scale(1, 1);
  transition-property: all;
  transition-duration: 0.22s;
  transition-timing-function: linear;
  transition-delay: 0s;
}

.score-table__title-row > div[class~="c-data__title"]:last-of-type {
  border-top-right-radius: 16px;
}

.score-table__title-row > div[class~="c-data__title"]:first-of-type {
  border-top-left-radius: 16px;
}

.score-change {
  background-color: #faf9ed;
  border-left: 1px solid #e4e0be;
  border-right: 1px solid #e4e0be;
  border-bottom: 1px solid #e4e0be;
}

.score-change > div:first-of-type > div:last-of-type {
  margin-right: 0;
}

.postpone-checkbox {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.postpone-checkbox__checkbox {
  display: flex;
  margin: 0;
  padding: 0;
  width: 32px;
  height: 32px;
}

.postpone-checkbox__label {
  padding-left: 8px;
  padding-bottom: 4px;
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: 500;
  color: #E71D36;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.kanri-dev-navigation {
  display: flex;
  position: fixed;
  top: 16px;
  left: 16px;
  color: white;
  z-index: 1001;
}

.kanri-dev-navigation a {
  color: inherit;
}

.kanri-dev-navigation input[type="checkbox"] {
  display: none;
}

.kanri-dev-navigation__group {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 16px;
  padding: 16px;
  background-color: rgba(255, 255, 255, 0.11);
  border-radius: 24px;
}

.kanri-dev-navigation__link {
  margin-top: 4px;
  padding: 4px 8px;
}

.kanri-dev-navigation__link:hover {
  border-bottom: 1px solid rgba(255, 255, 255, 0.55);
  animation: pulse 0.55s both;
}

.kanri-dev-navigation__link:hover a {
  color: #023342;
}

.kanri-dev-navigation__link:hover i {
  background-color: rgba(2, 51, 66, 0.98);
  color: white;
}

.kanri-dev-navigation__link i {
  font-size: 1rem;
  background: none;
  border: 1px solid white;
  margin-right: 8px;
  padding: 0 8px;
  border-radius: 4px;
}

.kanri-dev-navigation__link--highlight {
  background-color: #F26419;
  border-radius: 4px;
}

.kanri-dev-navigation__link--highlight:hover {
  color: #F26419;
}

.kanri-dev-navigation__checkbox:checked ~ .kanri-dev-navigation__dropdown {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 144px);
  overflow: scroll;
}

.kanri-dev-navigation__checkbox:checked ~ .kanri-dev-navigation__button {
  background-color: #023342;
}

.kanri-dev-navigation__button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 32px;
  height: 32px;
  margin: 8px;
  padding: 4px;
  border: none;
  border-radius: 50%;
  outline: none;
  z-index: 5;
}

.kanri-dev-navigation__button svg {
  width: 32px;
  height: 32px;
}

.kanri-dev-navigation__button:hover {
  animation: rotate-in-center 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.kanri-dev-navigation__dropdown {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  min-width: 144px;
  min-height: 144px;
  padding: 32px 8px;
  background-color: rgba(2, 51, 66, 0.98);
  border: 1px solid rgba(255, 255, 255, 0.44);
  border-radius: 8px;
}
/*# sourceMappingURL=styles.css.map */
#loading-wrapper { 
  position: fixed;
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
  background: #c0c0c0; 
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  transition: all 0.3s; 
  z-index: 9999;
  opacity: 0.9; 
} 

.loader_font{
    background-color: #c0c0c0;
    color: #ffffff;
} 

.loader {

  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: load5 1.1s infinite ease;
  transform: translateZ(0);
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@font-face {
  font-family: "icomoon";
  src: url(/static/media/icomoon.4989cd59.eot);
  src: url(/static/media/icomoon.4989cd59.eot#iefix) format("embedded-opentype"),
    url(/static/media/icomoon.c7ff9933.ttf) format("truetype"),
    url(/static/media/icomoon.2c1495e3.woff) format("woff"),
    url(/static/media/icomoon.6e2dd00b.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-eye-open:before {
  content: "\e900";
}
.icon-eye-closed:before {
  content: "\e904";
}
.icon-plus:before {
  content: "\e901";
}
.icon-minus:before {
  content: "\e902";
}
.icon-check:before {
  content: "\e903";
}

table , td, th {
    border: 1px solid #023342;
    border-collapse: collapse;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    }
  td, th {
  padding: 3px;
  width: 100px;
  height: 25px;
  }
  
